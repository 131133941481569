<template>
  <div class="signon__container">
    <dy-base-article class="signon__article">
      <template #header>
        <div class="signon__logo">
          <img
            :src="`/2025_logo.png?v=${applicationVersion}`"
            :alt="applicationName"
            :title="applicationName"
          />
        </div>
      </template>

      <dy-base-suspense>
        <template #default>
          <div v-if="isAuthenticationError" class="signon__error">
            <dy-base-icon icon="action/error" />

            <span v-html="authenticationError" />
          </div>

          <h1 class="signon__title">
            {{ $t('auth.titles.signOn') }}
          </h1>

          <div v-if="linkSent" v-html="waitForEmail" />

          <div v-if="linkSent">
            <br />
            <br />
          </div>

          <p v-else v-html="enterEmailAddress"></p>

          <dy-sign-on
            v-if="!linkSent"
            :schema="schema"
            :doc="doc"
            :docErrors="docErrors"
            @runSignOn="runSignOn"
            @runUpdateFieldValue="runUpdateFieldValue"
          />

          <dy-help />
        </template>
      </dy-base-suspense>
    </dy-base-article>
  </div>
</template>

<script>
  import runAction from './../../../widgets/page/run_action'
  import validateField from './../../../setup/client/crud/validate_field.wrapper'

  import Help from './../../../widgets/help/components/Help.vue'

  import SignOnForm from './../components/SignOnForm.vue'

  import { Schema } from './../auth.schema'
  import spawn from './../spawn_auth'

  export default {
    name: 'SignOn',

    components: {
      'dy-sign-on': SignOnForm,
      'dy-help': Help
    },

    data() {
      return {
        doc: spawn(),
        docErrors: {},
        linkSent: false
      }
    },

    computed: {
      applicationName() {
        return process.env.VUE_APP_app_name
      },

      applicationVersion() {
        return process.env.VUE_APP_app_version
      },

      schema() {
        return Schema()
      },

      enterEmailAddress() {
        return this.$t('auth.help.enterEmailAddress')
      },

      waitForEmail() {
        return this.$t('auth.help.waitForEmail', {
          application: process.env.VUE_APP_app_name,
          email: this.doc.email
        })
      },

      isAuthenticationError() {
        return this.$store.getters['auth/isAuthenticationError']
      },

      authenticationError() {
        return this.$t(this.$store.getters['auth/getAuthenticationError'])
      }
    },

    methods: {
      runUpdateFieldValue(payload) {
        this.$store.commit('auth/setAuthenticationError', '')

        const { fieldName, fieldValue } = { ...payload }
        if (fieldName !== 'email') {
          return
        }

        return validateField(Schema)(this.doc)(fieldName)(fieldValue).then(
          data => {
            this.doc.email = data.fieldValue
            this.docErrors.email = data.fieldErrors
            return this.docErrors.email && this.docErrors.email.length === 0
          }
        )
      },

      runSignOn() {
        this.$store.commit('auth/setAuthenticationError', '')

        const payload = {
          fieldName: 'email',
          fieldValue: this.doc.email
        }

        return this.runUpdateFieldValue(payload).then(isValid => {
          if (isValid === false) {
            return
          }

          const params = {
            store: this.$store,
            router: this.$router
          }

          const actionFn = () =>
            this.$store.dispatch('auth/runSendSignOnLink', {
              email: this.doc.email.toLowerCase()
            })
          return runAction(params)(actionFn)().then(hasLinkBeenSent => {
            this.linkSent = hasLinkBeenSent
            if (hasLinkBeenSent === false) {
              this.$store.commit(
                'auth/setAuthenticationError',
                'fbauth.errors.signOnEmailFailed'
              )
            }
            return true
          })
        })
      }
    },

    created() {
      const params = {
        store: this.$store,
        router: this.$router
      }

      const actionFn = () => this.$store.dispatch('locale/runReadGuest')

      return runAction(params)(actionFn)()
    }
  }
</script>

<style lang="scss">
  .signon__container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
  }

  .signon__logo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 1em;

    img {
      width: 100%;
      max-width: 200px;
      height: auto;
    }
  }

  .signon__article {
    background-color: white;
    padding: 2em;
    width: 95%;
    border-radius: 16px;
    border: 4px solid #dadce0;

    p {
      margin-bottom: 1.25em;
    }

    .form__field label {
      text-align: left;
      padding-right: 0;
    }

    .form__buttons {
      justify-content: center;
      margin-left: 0;

      .button {
        margin: 0 auto;
      }
    }

    @media only screen and (min-width: $mq-phone) {
      .form__field label {
        padding-right: 0.5em;
        width: 25%;
      }
    }
  }

  @media (min-width: $mq-tablet) {
    .signon__container {
      align-items: center;
      height: 100vh;
    }

    .signon__article {
      min-height: 45vh;
      max-width: 37.5em;
      width: 60vw;
    }
  }

  .signon__title {
    margin-bottom: 1em;
    text-align: center;
  }

  .signon__error {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    background-color: #a74343;
    color: white;
    margin: 0 auto 1.75em;
    padding: 0.5em;
    border: 2px solid #a74343;
    border-radius: 1em;
    opacity: 1;

    .icon__svg {
      width: 1.5em;
      height: 1.5em;
      margin-right: 0.5em;
    }
  }
</style>
